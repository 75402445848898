//const URL_API = 'http://localhost:4000/wifi/api/v1/ap/';
const URL_API = 'https://enlinea.iparatodos.com.ar/wifi/api/v1/ap';

export const getAps = async () => {
  try{
    const res = await fetch(URL_API);
    return res.json();
  } catch (err) {
    throw new Error(err)
  }
}

export const getPlazas = async () => {
  try{
    const res = await fetch(`${URL_API}/plazas`);
    return res.json();
  } catch (err) {
    throw new Error(err)
  }
}