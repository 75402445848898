import React, {useState, useEffect} from "react";
import { Marker, Popup } from 'react-leaflet'
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import '../../index.css';

// import L from 'leaflet';

// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png')
// });

export const MarkerSatus = ( props ) => {
  const { name, lat, lon, status, connected, cantidad_ap, color } = props;

  const [ markerColor, setMarkerColor ] = useState('green');

  useEffect( () => {

    // switch(cantidad_ap){
    //   case 1:
    //     status < 100 ? setMarkerColor('green') : setMarkerColor('red');
    //     break;
    //   default:
    //     switch(status) {
    //       case 0:
    //         setMarkerColor('green');
    //         break;
    //       case 100:
    //         setMarkerColor('red');
    //         break;
    //       default:
    //         setMarkerColor('orange');
    //         break;
    //     }
    //     break;
    // }

  }, [status])

  const iconMarkup = renderToStaticMarkup(
    <i className=" fa fa-circle fa-2x" style={{ color: color === 'orange' ?  'orange'  : 'green' }} />
  );

  const customMarkerIcon = divIcon({
    html: iconMarkup
  });

  return (
    <Marker position={[lat, lon]} icon={customMarkerIcon} >
      <Popup>
        <h3>{name}</h3>
        <b>Cantidad AP:</b> { cantidad_ap }
        <br/>
        <b>Conectados:</b> { status !== 100 ? connected : '' }
      </Popup>
    </Marker>
  )
}