import { getAps, getPlazas } from "../../api/api";

export const GET_ALL_AP = 'GET_ALL_AP';
export const GET_ALL_PLAZAS = 'GET_ALL_PLAZAS';

// Actions creator
const getAllApAction = (ap) => ({type: GET_ALL_AP, payload: ap});
const getAllPlazas = (plaza) => ({type: GET_ALL_PLAZAS, payload: plaza});

// Async actions
export const allAp = () => {
  return async dispatch => {
    try {
      const ap = await getAps()
      dispatch(getAllApAction(ap))
    } catch(err) {
      console.log(err)
    }
  }
}

export const allPlazas = () => {
  return async dispatch => {
    try {
      const plazas = await getPlazas()
      dispatch(getAllPlazas(plazas))
    } catch(err) {
      console.log(err)
    }
  }
}