import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

const logoLaRioja = require('../assets/img/logo-larioja.png');
const logoEnLinea = require('../assets/img/logo-enlinea.png');
const logoIPT = require('../assets/img/logo-ipt.png');

export const Navbar = () => (
  <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" style={{ backgroundColor: '#ea1b36'}}>
      <Toolbar>
        <img src={logoLaRioja} height="40" width="130" style={{padding:10}} alt="La Rioja" />
        
        <img src={logoIPT} height="50" width="100" style={{padding:10}} alt="Internet Para Todos" />
      </Toolbar>
    </AppBar>
  </Box>
)