// import logo from './logo.svg';
// import './App.css';
import { Provider } from 'react-redux';
import { Navbar } from './components/navbar';
import { MapView } from './views/mapView';

import store from './redux/store';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <header className="App-header">

          <Navbar />
          <MapView />

        </header>
      </div>
    </Provider>
  );
}

export default App;
