const SET_VIEW_AP = "SET_VIEW_AP";
const SET_VIEW_PLAZAS = "SET_VIEW_PLAZAS";

const stateInitial = {
  ViewAp: true,
  ViewPlazas: true
};

export const appReducer = (state = stateInitial, action) => {
  console.log('action', action)
  switch (action.type) {
    case SET_VIEW_AP:
      return {
        ...state,
        ViewAp: action.payload,
      };
    case SET_VIEW_PLAZAS:
      return {
        ...state,
        ViewPlazas: action.payload,
      };
    default:
      return state;
  }
};

export const setViewAp = viewAp => {
  return dispatch => {
    dispatch({
      type: SET_VIEW_AP,
      ViewAp: viewAp
    });
  }
};

export const setViewPlazas = viewPlazas => {
  return dispatch => {
    dispatch({
      type: SET_VIEW_PLAZAS,
      ViewPlazas: viewPlazas
    });
  }
};