import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Hidden } from '@mui/material';
import { Grid, Switch } from '@mui/material';

import { useDispatch, useSelector } from "react-redux";
import { setViewAp } from "../../redux/reducers/index";

const logoEnLinea = require('../../assets/img/logo-enlinea.jpeg');

const styleSmallCard = {
  zIndex: 999,
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
}

export const CardDeviceInfo = (props) => {

  const { style, apData } = props;

  const dispatcher = useDispatch();

  const handleChange = (event) => {
    const checked = event.target.checked;
    dispatcher(setViewAp(checked));
  };

  return (
    <>
      <Hidden only={['xs', 'sm']}>
        <Card sx={{ maxWidth: 400 }} style={style} >
          <CardMedia
            component="img"
            height="140"
            image={logoEnLinea}
            alt="En Linea"
          // style={{ padding: 10 }}
          />
          <CardContent>
            <Typography gutterBottom variant="body2" component="div">
              {/* <b>Localidades:</b> {apData.length} */}
              <b>Localidades:</b> 240
            </Typography>
            <Typography variant="body2" color="text.primary">
              <b>Cantidad AP:</b> {apData.reduce((acu, currentValue) => {
                return acu + currentValue.cantidad_ap
              }, 0)}
            </Typography>
            <Typography variant="body2" color="text.primary">
              {/* <b>Conectados:</b> {apData.reduce((acu, currentValue) => {
                    return acu + currentValue.conectados
                  }, 0)} */}
              <b>Conectados:</b> 6706
            </Typography>
            {/* <Typography gutterBottom variant="body2" component="div">
              <b style={{ color: 'green' }}>Actualizado:</b> { apData.length > 0 ? new Date(apData[0].fecha_hora).toLocaleString() : '' }
            </Typography> */}

            {/* <Button onClick={ () => dispatcher(setViewAp(false)) }> AP </Button> */}
            {/* <Switch defaultChecked label="APs Rurales" onChange={ handleChange } /> APs Rurales */}
            <br />
            {/* <Switch defaultChecked label="Plazas" /> Plazas */}
            {/* <Switch { inputProps: { 'aria-label': 'Switch demo' } } defaultChecked /> */}

          </CardContent>
        </Card>
      </Hidden>


      <Hidden mdUp={true}>
        <Card sx={{ maxWidth: '100%' }} style={styleSmallCard} >
          <CardContent>

            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography gutterBottom variant="body2" component="div">
                  {/* <b>Localidades:</b> {apData.length} */}
                  <b>Localidades:</b> 240
                </Typography>
                <Typography variant="body2" color="text.primary">
                  <b>Cantidad AP:</b> {apData.reduce((acu, currentValue) => {
                    return acu + currentValue.cantidad_ap
                  }, 0)}
                </Typography>
                <Typography variant="body2" color="text.primary">
                  {/* <b>Conectados:</b> {apData.reduce((acu, currentValue) => {
                    return acu + currentValue.conectados
                  }, 0)} */}
                  <b>Conectados:</b> 6706
                </Typography>
                {/* <Typography gutterBottom variant="body2" component="div">
                  <b style={{ color: 'green' }}>Actualizado:</b> { apData.length > 0 ? new Date(apData[0].fecha_hora).toLocaleString() : '' }
                </Typography> */}
              </Grid>

              <Grid item xs={5} style={{ justifyContent: 'center', textAlign: 'center' }}>
                <img src={logoEnLinea} height="80" width="120" alt="En Linea" />
              </Grid>
            </Grid>


          </CardContent>
        </Card>
      </Hidden>
    </>

  );
}