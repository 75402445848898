import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { appReducer } from "./reducers";
import { accessPointsReducer } from "./reducers/accessPointsReducer";
// import { pharmaciesReducer } from "./reducers/pharmacies/pharmaciesReducer";

const rootReducers = combineReducers({
  app: appReducer,
  accessPointsReducer: accessPointsReducer
});

const store = createStore(rootReducers, applyMiddleware(thunk));

export default store;