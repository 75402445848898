import React, { useState, useEffect } from "react";
import { getAps } from "../api/api";
import { Map } from "../components/map/map";
import { CardDeviceInfo } from "../components/devices/cardDeviceInfo";
import { useDispatch, useSelector } from "react-redux";
import { allAp, allPlazas } from "../redux/actions/accessPointsActions";

const stylesCard = {
  position: 'absolute',
  zIndex: 999,
  marginTop: '10vh',
  marginLeft: '3vh',
};

export const MapView = () => {

  const apData = useSelector((state) => state.accessPointsReducer.ap);
  const dispatcher = useDispatch();

  // const [apData, setApData] = useState([]);

  // useEffect(() =>
  //   async () => {
  //     const ap = await getAps();
  //     setApData(ap);

  //     setInterval( async () => {
  //       const ap = await getAps();
  //       setApData(ap);
  //     }, 300000 ) // 5 minutes

  //   }, [])

  useEffect( () => {
    dispatcher(allAp());
    dispatcher(allPlazas());
  },[])

  return (
    <div>
      <CardDeviceInfo apData={apData} style={stylesCard} />
      <Map />
    </div>
  )
}