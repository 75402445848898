import { GET_ALL_AP, GET_ALL_PLAZAS } from "../actions/accessPointsActions";

const initialState = {
  ap: [],
  plazas: []
}

export const accessPointsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_AP:
      return { ...state, ap: payload }
    case GET_ALL_PLAZAS:
      return { ...state, plazas: payload }
    default:
      return state
  }
}