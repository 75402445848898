import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

import { useDispatch, useSelector, useStore } from "react-redux";
import { allAp } from "../../redux/actions/accessPointsActions";

import { MarkerSatus } from "./marker";
import { Button } from "@mui/material";

const mapPosition = [-29.4142176, -66.8907965];

export const Map = () => {

  const store = useStore()
  const dispatcher = useDispatch();

  const apData = useSelector((state) => state.accessPointsReducer.ap);
  const plazasData = useSelector((state) => state.accessPointsReducer.plazas);
  const viewAp = useSelector((state) => state.app.ViewAp);

  useEffect( () => {
    console.log(viewAp)
  }, [viewAp])

  return (
    <>
      <MapContainer center={mapPosition} zoom={8} scrollWheelZoom={true} style={{ height: '100vh', width: '100wh' }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {
          // viewAp ?
          viewAp && apData.map(a => <MarkerSatus name={a.nombre} lat={Number(a.latitud)} lon={Number(a.longitud)} status={a.estado} connected={a.conectados} cantidad_ap={a.cantidad_ap} />)
        }

        {
          plazasData && plazasData.map(a => <MarkerSatus name={a.nombre} lat={Number(a.latitud)} lon={Number(a.longitud)} status={a.estado} connected={a.conectados} cantidad_ap={a.cantidad_ap} color='orange' />)
        }

      </MapContainer>
    </>
  )
}